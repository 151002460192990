<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <div class="p-inputgroup">
            <InputText
              v-model.trim="keyword"
              required="true"
              :class="{ 'p-invalid': submitted && !keyword }"
              placeholder="Search"
            />
            <Button
              icon="pi pi-search "
              class="p-button-primary p-mr-1"
              @click="loadSearchData"
            />
          </div>
          <div class="p-mx-2">
            <Button
              icon="pi pi-plus"
              class="p-button-success"
              @click="openDialog"
            />
          </div>
        </template>
      </Toolbar>
      <div class="p-mt-2">
        <DataTable
          :value="lists"
          :lazy="true"
          :paginator="checkPagination"
          :rows="limit"
          :totalRecords="totalRecords"
          :scrollable="true"
          @page="onPage($event)"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
          <template #empty>
            <div class="p-text-center p-p-3">No records found</div>
          </template>
          <Column field="survey_id" header="Survey ID"></Column>
          <Column field="survey_question" header="Question"></Column>
          <Column field="answer" header="Answer"></Column>
          <Column field="branch_type" header="Branch Type"></Column>
          <Column field="status" header="Status"></Column>
          <Column field="created_date" header="Created date"></Column>
          <Column :exportable="false" header="Action">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary p-mr-2"
                @click="editIem(slotProps.data)"
              />
              <Button
                v-if="slotProps.data.status == 'Active'"
                icon="pi pi-eye-slash"
                class="p-button-rounded p-button-danger"
                @click="confirmChangeStatus(slotProps.data, 'Inactive')"
              />
              <Button
                v-else-if="slotProps.data.status == 'Inactive'"
                icon="pi pi-eye"
                class="p-button-rounded p-button-warning"
                @click="confirmChangeStatus(slotProps.data, 'Active')"
              />
            </template>
          </Column>
        </DataTable>
      </div>

      <Dialog
        v-model:visible="productDialog"
        :style="{ width: '70vw' }"
        :maximizable="true"
        position="top"
        class="p-fluid"
         :modal="true"
        :closable="false"
      >
        <template #header>
          <h4 class="p-dialog-titlebar p-dialog-titlebar-icon">
            {{ dialogTitle }}
          </h4>
        </template>
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-6">
            <div class="p-field">
              <label for="question">Survey Question: (required):</label>
              <InputText
                id="question"
                v-model.trim="product.question"
                required="true"
                placeholder="Write Survey Question"
                autofocus
                :class="{ 'p-invalid': submitted && !product.question }"
              />
              <small class="p-invalid" v-if="submitted && !product.question"
                >Survey Question is required.</small
              >
            </div>

            <div class="p-field">
              <label for="opt1">Option 1 (required):</label>
              <InputText
                id="opt1"
                v-model.trim="product.opt1"
                required="true"
                autofocus
                :class="{ 'p-invalid': submitted && !product.opt1 }"
                placeholder="Write Survey Answer(Required)"
              />
              <small class="p-invalid" v-if="submitted && !product.opt1"
                >Option 1 is required.</small
              >
            </div>

            <div class="p-field">
              <label for="opt2">Option 2 (required):</label>
              <InputText
                id="opt2"
                v-model.trim="product.opt2"
                required="true"
                autofocus
                :class="{ 'p-invalid': submitted && !product.opt2 }"
                placeholder="Write Survey Answer(Required)"
              />
              <small class="p-invalid" v-if="submitted && !product.opt2"
                >Option 2 is required.</small
              >
            </div>

            <div class="p-field">
              <label for="opt3">Option 3:</label>
              <InputText
                id="opt3"
                v-model.trim="product.opt3"
                placeholder="Optional"
              />
            </div>

            <div class="p-field">
              <label for="opt4">Option 4</label>
              <InputText
                id="opt4"
                v-model.trim="product.opt4"
                placeholder="Optional"
              />
            </div>
          </div>
          <div class="p-col-12 p-md-6">
            <div class="p-field">
              <label for="opt5">Option 5</label>
              <InputText
                id="opt5"
                v-model.trim="product.opt5"
                placeholder="Optional"
              />
            </div>

            <div class="p-field">
              <label for="correctOpt">Choose Correct Option:</label>
              <Dropdown
                v-model="product.correctOpt"
                :options="allOptions"
                optionLabel="value"
              />
            </div>

            <div class="p-field">
              <label for="destination">Destination:</label>
              <Dropdown
                v-model="product.destination"
                :options="destinations"
                optionLabel="value"
              />
            </div>

            <div class="p-field">
              <label for="filterStore">Filter Stores:</label>
              <MultiSelect
                v-model="product.filterStores"
                :options="allBranches"
                :filter="true"
                optionLabel="mall"
                display="chip"
              />
            </div>
          </div>
        </div>

        <template #footer>
          <Button
            type="submit"
            label="Save"
            icon="pi pi-check"
            class="p-button-primary"
            @click="saveItem"
          />
          <Button
            label="Close"
            icon="pi pi-times"
            class="p-button-danger"
            @click="closeDialogBox"
          />
        </template>
      </Dialog>

      <Dialog
        v-model:visible="statusDialog"
        :style="{ width: '450px' }"
        header="Confirm"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span v-if="product"
            >Change the status of <b>{{ product.question }}</b> to
            {{ productStatus }}?</span
          >
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text"
            @click="statusDialog = false"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            class="p-button-text"
            @click="changeStatus"
          />
        </template>
      </Dialog>
    </div>
  </section>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Surves from "../../service/Surves";
import Toaster from "../../helpers/Toaster";
import { ISurvey, branchDetail } from "../survey/ISurvey";

@Options({
  components: {},
})
export default class Survey extends Vue {
  private imagePath = "";
  private keyword = "";
  private checkPagination = true;
  private lists = [];
  private Surves;
  private allBranches: Array<branchDetail> = [];
  private productStatus = "";
  private productDialog = false;
  private statusDialog = false;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Survey" },
  ];
  private toast;

  private product: ISurvey = {
    id: "",
    question: "",
    opt1: "",
    opt2: "",
    opt3: "",
    opt4: "",
    opt5: "",
    correctOpt: {},
    destination: {},
    filterStores: [],
    status: "",
  };

  private allOptions = [
    { key: "option_1", value: "Option 1" },
    { key: "option_2", value: "Option 2" },
    { key: "option_3", value: "Option 3" },
    { key: "option_4", value: "Option 4" },
    { key: "option_5", value: "Option 5" },
  ];

  private destinations = [
    { key: "All Branches", value: "All Stores" },
    { key: "Specific Branches", value: "Specific Stores" },
  ];

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.Surves = new Surves();
    this.toast = new Toaster();
    this.imagePath = this.Surves.getBaseURL() + "uploads/services/";
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.submitted = false;
    this.dialogTitle = "Create Survey Question";
    this.productDialog = true;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();

    this.submitted = true;
    if (this.product.question.trim()) {
      if (this.product.id != "") {
        this.Surves.updateItem(this.product).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.Surves.saveItem(this.product).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.clearItem();
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Survey Question";
    this.productDialog = true;

    this.Surves.getItem(data).then((res) => {
      if (res.length > 0) {
        //FIND THE CATEGORY TYPE AND MAKE IT AS SELECTED IN EDIT DIALOG BOX.
        const stores = this.storesFilter(res[0].branches);
        const allOptions = this.allOptionsFilter(res[0].answer);
        const destinations = this.destinationFilter(res[0].branch_type);

        this.product.id = res[0].survey_id;
        this.product.question = res[0].survey_question;
        this.product.opt1 = res[0].option_1;
        this.product.opt2 = res[0].option_2;
        this.product.opt3 = res[0].option_3;
        this.product.opt4 = res[0].option_4;
        this.product.opt5 = res[0].option_5;
        this.product.correctOpt = allOptions;
        this.product.destination = destinations;
        this.product.filterStores = stores;
        this.product.status = status;
      }
    });
  }

  //USED TO FILTER TYPES
  storesFilter(res) {
    const stores: Array<branchDetail> = [];
    this.allBranches.filter((elem) => {
      res.filter((storedElem) => {
        if (elem.code == storedElem) {
          stores.push({
            code: elem.code,
            mall: elem.mall,
          });
        }
      });
    });

    return stores;
  }

  //USED TO FILTER TYPES
  allOptionsFilter(res) {
    let options = {};

    this.allOptions.filter((elem) => {
      if (elem.key == res) {
        options = elem;
      }
    });
    return options;
  }

  //USED TO FILTER TYPES
  destinationFilter(res) {
    let dest = {};

    this.destinations.filter((elem) => {
      if (elem.key == res) {
        dest = elem;
      }
    });
    return dest;
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.product.id = data.survey_id;
    this.product.question = data.survey_question;
    this.product.status = data.status;
    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.product.status = this.productStatus;
    this.Surves.changeStatus(this.product).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.Surves.getItems(page).then((data) => {
      this.lists = data.survey_record;
      this.totalRecords = data.total_survey_count;
      this.limit = data.limit_per_page;
      this.allBranches = data.branches_list;
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.Surves.getSearchedSurves(this.keyword).then((data) => {
        this.lists = data.survey_record;
        this.allBranches = data.branches_list;
        this.checkPagination = data.pagination;
      });
    }
  }

  clearItem() {
    this.product.id = "";
    this.product.question = "";
    this.product.opt1 = "";
    this.product.opt2 = "";
    this.product.opt3 = "";
    this.product.opt4 = "";
    this.product.opt5 = "";
    this.product.correctOpt = {};
    this.product.destination = {};
    this.product.filterStores = [];
    this.product.status = "";
  }

  //CLOSE THE ITEM DAILOG BOX
  closeDialogBox() {
    this.productDialog = false;
    this.submitted = false;
    this.clearItem();
  }
}
</script>

<style scoped>
.product-image {
  width: 30px;
  height: auto;
}
</style>
